.Login {
    height: 100vh;
    width: 600px;
    float: right;
    position: relative;
    background: #ffffff;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
        0 7px 70px rgba(90, 97, 105, 0.1);
}

.Login-form-forgot {
    float: right;
}

.Login-form-button {
    width: 100%;
}

.Login-header {
    text-align: center;
}

.Login-header-text {
    font-size: 32px;
    font-weight: 200;
}

.c-reset-password .header-img {
    height: 50px;
    margin-top: 30px;
}
