body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #ecf0f5;
}
.ant-table-pagination.ant-pagination {
    margin: 16px 10px;
}
.ant-layout-header {
    height: 60px;
    line-height: 50px;
}
.ant-layout-sider {
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}
.ant-drawer-body {
    padding: 0px;
}
.ant-table.ant-table-bordered .ant-table-title {
    background: #fafafa;
}
.ant-table-small {
    border: none;
}
.ant-table-title {
    font-weight: bold;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
    padding: 10px;
}
.ant-table-footer {
    background: white;
}
/* .ant-input-group-addon:first-child {
    width: 155px;
} */

.ab1 {
    color: #1890ff;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}
.a1 {
    font-size: 15px;
    font-weight: bold;
    color: #595959;
}
.view-title {
    font-weight: bold;
    font-size: 18px;
}

/* .ant-menu-horizontal .ant-menu-item {
    padding-bottom: 6px;
}
.ant-menu-horizontal .ant-menu-submenu {
    padding-bottom: 6px;
} */

.ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #595959;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}

.root-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
    background: #ffffff;
}

.root-button {
    width: 100%;
    padding: 0px 16px;
    border-radius: 2px;
    border: 0px;
    outline: none;
    cursor: pointer;
    transition: background 0.5s ease 0s;
    height: 40px;
    background-image: linear-gradient(315deg, rgb(0, 195, 234), rgb(0, 144, 209));
    background-color: rgb(0, 195, 234) !important;
    color: rgb(255, 255, 255);
    text-shadow: rgb(21 41 53 / 24%) 1px 1px 0px;
    font-family: Roboto;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
}

.root-button:hover {
    background-image: rgb(0, 195, 234);
}

.adj-button {
    margin-top: 4px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffe6e6;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #0090d1;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
    color: #0090d1;
}
.ant-menu-item > a:hover {
    color: #0090d1;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #0090d1;
}
.loginBackground {
    height: 100vh;
    background-image: url("https://b2bstaticdev.blob.core.windows.net/static-apps/images/bo-background.png"),
        linear-gradient(315deg, #00c3ea, #0090d1);
}
._elevio_launcher {
    display: none;
}

.ant-steps-item-process .ant-steps-item-icon {
    background: #005075;
    border-color: #005075;
}

.ant-calendar-picker {
    width: 100%;
}

.ant-menu-submenu-selected {
    color: #0090d1;
}
.ant-menu-vertical .ant-menu-submenu-selected {
    color: #0090d1;
}

.ant-modal-wrap.ant-modal-centered {
    background: rgba(0, 80, 117, 0.32);
}
.ant-modal-content {
    border-radius: 0px;
}
.ant-modal-title {
    font-size: 20px;
    color: #005075;
}
.ant-modal-header {
    border-bottom: 2px solid #0090d1;
}
.ant-modal-body {
    padding: 30px 24px;
}
.ant-modal-footer {
    background: #f2f5f8;
}
.ant-modal-close-x {
    font-size: 20px;
    font-weight: bold;
    color: #0090d1;
}
